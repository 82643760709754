import React, {
  Fragment,
  useContext,
  useMemo,
  useRef,
  useState,
  useEffect,
} from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
//Context
import ContributionContext from '../../contexts/ContributionContext'
//Components
import { Button } from '../Button/Button'
import { Icon } from '../Icon/Icon'
import { ColorPropTypes } from '../settings/ColorPropTypes'
import { ObjectTitle } from './object_title'
//Helpers
import { convertImageUrlToIiif } from '../../helper-functions/string-functions'
import { ContentsContributionButton } from './contents_contribution_button'
import useUrlParams from '../../hooks/use-url-params'
import { DEFAULT_PAGE, DEFAULT_PANEL } from '../settings/globals'
import RecordContext from '../../contexts/RecordContext'
import { useLocation } from 'react-router-dom'
import { Pill } from '../Pill/Pill'
import { returnFileFormat, returnObjectTypeFromObject } from './object_preview'
import useObserver from '../../hooks/use-observer'
//Hooks
import useHttp from '../../hooks/use-http'

Array.prototype.contains = function (element) {
  return this.indexOf(element) > -1
}

export const ContentsPanel = ({
  // active,
  loaded,
  screenSize,
  extractedData: extractedItem,
  // handleSetContentsPanel,
  ...props
}) => {
  const label = 'Highlighted objects contain terms from your search'
  const contributionContext = useContext(ContributionContext)
  const {
    contentsPanel: active,
    filteredObjects: objects,
    handleSetActiveObjectPage,
    handleSetActivePanel,
    handleSetContentsPanel,
  } = useContext(RecordContext)

  // Get current params and param functions
  const { state } = useLocation()
  const { params, updateParams } = useUrlParams(state)
  const objectPanel = params['objectPanel'] || DEFAULT_PANEL
  const objectPage = params['objectPage'] || DEFAULT_PAGE

  const [activeContentsView, setActiveContentsView] = useState('grid')
  const IiifThumb = '/full/150,/0/default.jpg'

  const contributionButtonRef = useRef()
  const thumbnailButtonRef = useRef()

  const thumbs = useRef([])
  const { startObserve } = useObserver()
  useEffect(() => {
    sessionStorage.getItem('search')
    startObserve(Array.from(thumbs.current), sessionStorage.getItem('search'))
  }, [])

  const hasKeyword = (ele) => {
    let query = sessionStorage.getItem('search')?.toLowerCase()
    if (query?.trim()) {
      query = query.trim().replace(/"/g, '')
    } else {
      return false
    }
    const conts = getAllcontributions(ele)
    const desc = ele.objectDescription
    const extract = ele.extractedText
    const otherExtTxt = ele?.otherExtractedText?.map((oet) => {
      return oet.contribution
    })
    let metaData = (conts + ' ' + desc + ' ' + extract).toLowerCase()
    metaData += otherExtTxt ? ' ' + otherExtTxt.join(' ').toLowerCase() : ''
    const terms = query?.split(/\s+/)
    const found = terms?.every((term) => metaData.includes(term))

    return found
  }

  const getAllcontributions = (obj) => {
    const result = obj?.contributions
      ?.reduce((ac, cv) => {
        const _result = ac.concat(cv.contribution)
        return _result
      }, [])
      .join(' ')
    return result
  }

  // const setActivePanelHandler = (panel) => {
  //   updateParams({
  //     objectPanel: panel,
  //   })
  // }
  // const handleSetActiveObject = (page) => {
  //   updateParams({
  //     objectPage: page,
  //   })
  //   handleSetActiveObjectPage(page)
  // }
  const handleSetActiveContentsView = (view = 'grid') => {
    if (view == activeContentsView) return false
    setActiveContentsView(view)
  }

  const handleUpdateObject = (page) => {
    updateParams({
      objectPage: page,
    })
    handleSetActiveObjectPage(page)
  }

  const handleUpdatePageAndPanels = (page, panel) => {
    if (
      typeof extractedItem[0]?.extractedText !== 'string' &&
      typeof extractedItem[0]?.latestOET?.contribution !== 'string'
    ) {
      console.warn('Extracted text is not present in the data.')
    }

    updateParams({
      objectPage: page || '',
      objectPanel: panel || '',
    })
    handleSetActiveObjectPage(page)
    handleSetActivePanel(panel)
    handleSetContentsPanel(false)
  }
  const imageTypes = ['BMP', 'GIF', 'JP2', 'JPG', 'PNG', 'PDF', 'TIFF']
  const videoTypes = ['MOV', 'MP4', 'WMV']
  const audioTypes = ['MP3', 'WAV']
  const defaultViewJSX = useMemo(() => {
    return (
      loaded && (
        <div
          className={[
            'default-view',
            'contents-scroll',
            'flex-column',
            'grid-col',
            'height-full',
            'flex-align-start',
            'flex-justify-start',
            'overflow-y-scroll',
            'padding-05',
            'width-full',
          ].join(' ')}
          data-testid="nac-objects--thumbs"
          role="tablist"
        >
          {objects.map((object, index) => (
            <div
              // This is the one
              tabIndex={index}
              ref={(ele) => {
                thumbs.current[index] = ele
              }}
              title={hasKeyword(object) ? label : null}
              aria-describedby={`object-thumb--${index + 1}`}
              data-description={object.objectDescription}
              data-extracted={
                object.latestOET
                  ? object.latestOET?.contribution
                  : object.extractedText
              }
              data-contributions={getAllcontributions(object)}
              data-haskeywordmatch={hasKeyword(object)}
              aria-selected={objectPage == index + 1}
              role="tab"
              aria-controls="digital-object-preview"
              className={[
                'clickable',
                'flex-column',
                'grid-col-4',
                'mobile-lg:grid-col-6',
                'desktop-lg:grid-col-4',
                'flex-align-stretch',
                'flex-justify-center',
                'padding-05',
                'width-full',
                'highlight-digital-objects',
                objectPage == index + 1 && hasKeyword(object)
                  ? 'highlight'
                  : null,
              ].join(' ')}
              data-testid={`nac-objects--details-object-${index + 1}`}
              id={`object-thumb--${index + 1}`}
              key={object.objectUrl}
              onClick={() => {
                handleUpdateObject(index + 1)
              }}
            >
              <div
                className={[
                  `${
                    objectPage == index + 1
                      ? [
                          'bg-base-opacity-20',
                          'border-1px',
                          'border-white',
                          'margin-bottom-neg-1px',
                          'margin-top-neg-1px',
                        ].join(' ')
                      : 'hover:bg-base-opacity-20'
                  }`,
                  'grid-item',
                  'padding-1',
                ].join(' ')}
              >
                <span
                  className={[
                    'display-flex',
                    'flex-align-center',
                    'flex-gap-sm',
                    'flex-row',
                    'font-sans-3xs',
                    'text-bold',
                    'text-center',
                    'text-white',
                    'width-full',
                  ].join(' ')}
                  title={`Designator: ${
                    object.objectDesignator ||
                    `${returnObjectTypeFromObject(object)} ${index + 1}`
                  }${
                    object.objectDescription
                      ? `; Description: ${object.objectDescription}`
                      : ''
                  }`}
                >
                  <span className={['flex-1'].join(' ')}>
                    {returnFileFormat(object.objectFilename) == 'pdf' && (
                      <Pill
                        className={[
                          'margin-right-auto',
                          'radius-md',
                          'text-uppercase',
                        ].join(' ')}
                        filled
                        radius="md"
                        reduced
                        size="4xs"
                        theme="base-dark"
                        thin
                      >
                        pdf
                      </Pill>
                    )}
                  </span>
                  <span
                    className={[
                      'display-flex',
                      'flex-1',
                      'flex-align-center',
                      'flex-justify-center',
                      'flex-gap-xs',
                      'tablet-lg:flex-gap-sm',
                      'flex-row',
                    ].join(' ')}
                    aria-hidden
                  >
                    {index + 1}
                  </span>
                  <span className={['flex-1'].join(' ')}></span>
                </span>
                <div
                  className={[
                    // 'preview-image',
                    'display-block',
                    'grid-col',
                    'height-15',
                    'margin-bottom-1',
                    'nac-icon',
                    'nac-icon',
                    'nac-icon-grey',
                    'nac-icon--base-dark',
                    'nac-icon--bg-20',
                    // `nac-icon--image`,
                    'nac-icon--thick',
                    'padding-1',
                    'position-relative',
                    'text-center',
                    'width-full',
                  ].join(' ')}
                  onClick={() => {
                    handleUpdateObject(index + 1)
                  }}
                >
                  {returnObjectTypeFromObject(object) ? (
                    audioTypes.some(
                      (element) =>
                        object?.objectType?.includes(element) ||
                        object?.objectFilename?.includes(element)
                    ) ? (
                      <Icon
                        color="base-dark"
                        iconName="headphones"
                        className="margin-bottom-neg-5"
                      />
                    ) : videoTypes.some(
                        (element) =>
                          object?.objectType?.includes(element) ||
                          object?.objectFilename?.includes(element)
                      ) ? (
                      <Icon
                        iconName="video"
                        color="base-dark"
                        className="margin-bottom-neg-5"
                      />
                    ) : imageTypes.some(
                        (element) =>
                          object?.objectType?.includes(element) ||
                          object?.objectFilename?.includes(element)
                      ) ? (
                      <img
                        alt=""
                        className="height-full"
                        loading="lazy"
                        src={
                          convertImageUrlToIiif(object.objectUrl) + IiifThumb
                        }
                      />
                    ) : (
                      <Icon className="nac-icon--image" />
                    )
                  ) : null}
                </div>
              </div>
            </div>
          ))}
        </div>
      )
    )
  }, [loaded, objects, objectPage, contributionContext.tagsArray, objectPanel])

  const gridViewJSX = useMemo(() => {
    return (
      loaded && (
        <div
          className={[
            'grid-view',
            'contents-scroll',
            'flex-row',
            'grid-row',
            // 'height-full',
            'flex-align-start',
            'flex-justify-start',
            'overflow-y-scroll',
            'padding-1',
            'position-relative',
            //'padding-bottom-6',
            'width-full',
          ].join(' ')}
          data-testid="nac-objects--grid-details"
        >
          {objects.map((object, index) => (
            <div
              tabIndex={index}
              className={[
                'clickable',
                'flex-column',
                'grid-col-6 tablet-lg:grid-col-4',
                'padding-05',
                'flex-align-stretch',
                'flex-justify-center',
                'highlight-digital-objects',
              ].join(' ')}
              title={hasKeyword(object) ? label : null}
              aria-describedby={`object-thumb--${index + 1}`}
              data-description={object.objectDescription}
              data-extracted={
                object.latestOET
                  ? object.latestOET?.contribution
                  : object.extractedText
              }
              data-contributions={getAllcontributions(object)}
              data-haskeywordmatch={hasKeyword(object)}
              data-testid={`nac-objects--details-object-${index + 1}`}
              id={`object-thumb--${index + 1}`}
              key={object.objectUrl}
              ref={thumbnailButtonRef}
            >
              <div
                className={[
                  'grid-item',
                  `${
                    objectPage == index + 1
                      ? [
                          'bg-base-opacity-20',
                          'border-1px',
                          'border-white',
                          'margin-bottom-neg-1px',
                          'margin-top-neg-1px',
                          // 'highlight-digital-objects',
                        ].join(' ')
                      : 'hover:bg-base-opacity-20'
                  }`,
                  'padding-1',
                ].join(' ')}
              >
                <span
                  className={[
                    'display-flex',
                    'flex-align-center',
                    'flex-gap-sm',
                    'flex-row',
                    'font-sans-3xs',
                    'text-bold',
                    'text-center',
                    'text-white',
                    'width-full',
                  ].join(' ')}
                  title={`Designator: ${
                    object.objectDesignator ||
                    `${returnObjectTypeFromObject(object)}  ${index + 1}`
                  }${
                    object.objectDescription
                      ? `; Description: ${object.objectDescription}`
                      : ''
                  }`}
                >
                  <span className={['flex-1'].join(' ')}>
                    {returnFileFormat(object.objectFilename) == 'pdf' && (
                      <Pill
                        className={[
                          'margin-right-auto',
                          'radius-md',
                          'text-uppercase',
                        ].join(' ')}
                        filled
                        radius="md"
                        reduced
                        size="4xs"
                        theme="base-dark"
                        thin
                      >
                        {returnFileFormat(object.objectFilename)}
                      </Pill>
                    )}
                  </span>
                  <span aria-hidden className={['flex-1'].join(' ')}>
                    {index + 1}
                  </span>
                  <span className={['flex-1'].join(' ')}></span>
                </span>
                <div
                  className={[
                    'preview-image',
                    'display-block',
                    'grid-col',
                    'height-15',
                    'margin-bottom-1',
                    'nac-icon',
                    'nac-icon',
                    'nac-icon--base-dark',
                    'nac-icon--bg-20',
                    `nac-icon--image`,
                    'nac-icon--thick',
                    'padding-1',
                    'position-relative',
                    'text-center',
                    'width-full',
                  ].join(' ')}
                  onClick={() => {
                    handleUpdateObject(index + 1)
                  }}
                >
                  <img
                    alt=""
                    className="height-full"
                    loading="lazy"
                    src={convertImageUrlToIiif(object.objectUrl) + IiifThumb}
                  />
                </div>
                <div className="flex-row grid-row flex-justify-center flex-no-wrap z-500">
                  {['transcription', 'tag', 'comment', 'extracted'].map(
                    (type) => (
                      <Fragment key={type}>
                        <ContentsContributionButton
                          extractedData={extractedItem}
                          contribution={type}
                          index={index}
                          ref={contributionButtonRef}
                          object={object}
                          onClick={handleUpdatePageAndPanels}
                        />
                      </Fragment>
                    )
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )
    )
  }, [
    loaded,
    objects,
    objectPage,
    contributionContext.tagsArray,
    objectPanel,
    extractedItem,
  ])

  const listViewJSX = useMemo(() => {
    return (
      loaded && (
        <div
          className={[
            'list-view',
            'contents-scroll',
            'flex-column',
            'grid-col',
            'height-full',
            'overflow-y-scroll',
            //'padding-bottom-10',
            'padding-top-0',
            'padding-x-1',
            'width-full',
          ].join(' ')}
          data-testid="nac-objects--list-details"
        >
          {objects.map((object, index) => (
            <div
              tabIndex={index}
              className={[
                `${
                  objectPage == index + 1
                    ? 'bg-base-opacity-20 highlight-digital-objects'
                    : 'hover:bg-base-opacity-20 '
                }`,
                'border-bottom-1px',
                'border-base-darker',
                'clickable',
                'display-flex',
                'flex-gap',
                'flex-row',
                'grid-row',
                'padding-05',
                'flex-align-center',
                'flex-justify',
                'width-full',
              ].join(' ')}
              title={hasKeyword(object) ? label : null}
              aria-describedby={`object-thumb--${index + 1}`}
              data-description={object.objectDescription}
              data-extracted={
                object.latestOET
                  ? object.latestOET?.contribution
                  : object.extractedText
              }
              data-contributions={getAllcontributions(object)}
              data-haskeywordmatch={hasKeyword(object)}
              data-testid={`nac-objects--details-object-${index + 1}`}
              id={`object-thumb--${index + 1}`}
              key={object.objectUrl}
              ref={thumbnailButtonRef}
            >
              <div className="grid-col">
                <div
                  className={[
                    'border-0',
                    'flex-align-center',
                    'flex-gap',
                    'flex-row',
                    'grid-row',
                    'flex-no-wrap',
                  ].join(' ')}
                  onClick={() => {
                    handleUpdateObject(index + 1)
                  }}
                >
                  <div
                    className={[
                      'flex-shrink-0',
                      'height-8',
                      'nac-icon',
                      'nac-icon--base-dark',
                      'nac-icon--bg-70',
                      `nac-icon--image`,
                      'nac-icon--thick',
                      'padding-1',
                      'width-8',
                    ].join(' ')}
                  >
                    <img
                      alt=""
                      className="object-fit-cover width-full height-full"
                      loading="lazy"
                      src={convertImageUrlToIiif(object.objectUrl) + IiifThumb}
                    />
                  </div>
                  <ObjectTitle
                    className={['font-sans-2xs', 'text-white'].join(' ')}
                    index={index + 1}
                    label
                    object={object}
                    type={returnObjectTypeFromObject(object)}
                  />
                </div>
              </div>
              {['transcription', 'tag', 'comment', 'extractedText'].map(
                (type) => (
                  <div className="grid-col-2 text-center" key={type}>
                    <ContentsContributionButton
                      extractedData={extractedItem}
                      contribution={type}
                      format="list"
                      index={index}
                      object={object}
                      onClick={handleUpdatePageAndPanels}
                    />
                  </div>
                )
              )}
            </div>
          ))}
        </div>
      )
    )
  }, [loaded, objects, objectPage, objectPanel, extractedItem])

  return (
    <div
      aria-expanded={active}
      aria-labelledby="contents-tab"
      className={[
        //'anim-all',
        //'anim-duration-1000ms',
        'bg-base-darkest',
        screenSize == 'mobile'
          ? 'border-left-0'
          : 'border-base-darker border-left-1px',
        'display-flex',
        'flex-column',
        'overflow-hidden',
        'shadow-md',
        'z-500',
      ].join(' ')}
      data-testid="nac-objects--details-panel"
      id="contents-panel"
      role="tabpanel"
      style={{
        width: `${active ? '100%' : screenSize == 'mobile' ? '0px' : '200px'}`,
      }}
    >
      {/**
       * Contents Panel Header
       */}
      <div
        className={[
          'border-bottom-1px border-base-darker',
          'display-flex',
          'flex-column',
          'margin-top-neg-1px',
          'padding-x-105',
          'width-full',
        ].join(' ')}
      >
        <div
          className={[
            'display-flex',
            'flex-row',
            'flex-align-center',
            'grid-row',
            'width-full',
          ].join(' ')}
        >
          {active && (
            <div
              className={[
                'display-flex',
                'flex-gap',
                'flex-row',
                'grid-row',
              ].join(' ')}
            >
              <Button
                aria-label={`View the contents in a grid.`}
                data-testid="nac-objects--details-grid-button"
                iconName="grid"
                iconSize="xs"
                onClick={() => handleSetActiveContentsView('grid')}
                outline={activeContentsView == 'grid'}
                reduced
                size="2xs"
                textOnly={activeContentsView !== 'grid'}
                theme="white"
                thin
              >
                Grid
              </Button>
              <Button
                aria-label={`View the contents in a list.`}
                data-testid="nac-objects--details-list-button"
                iconName="list"
                iconSize="xs"
                onClick={() => handleSetActiveContentsView('list')}
                outline={activeContentsView == 'list'}
                reduced
                size="2xs"
                textOnly={activeContentsView !== 'list'}
                theme="white"
                thin
              >
                List
              </Button>
            </div>
          )}
          <div className="margin-left-auto">
            <Button
              aria-label={
                active
                  ? 'Hide the object details.'
                  : 'Access more object details.'
              }
              className="margin-top-1px"
              data-testid="nac-objects--toggle-details-button"
              iconName={active ? 'minus' : 'plus'}
              iconSize="xs"
              onClick={() => handleSetContentsPanel()}
              reduced
              size="2xs"
              textOnly
              theme="white"
              thin
            >
              {active ? 'Hide Details' : 'Show Details'}
            </Button>
          </div>
        </div>
        {active && (
          <>
            {activeContentsView == 'list' && (
              <div
                className={[
                  'display-flex',
                  'flex-gap',
                  'flex-row',
                  'grid-row',
                  'padding-05',
                  'flex-align-center',
                  'flex-justify',
                  'font-sans-3xs',
                  'margin-top-1',
                  'padding-1',
                  'text-base-light',
                  'text-bold',
                  'width-full',
                ].join(' ')}
              >
                <div className="grid-col">Object</div>
                <div className="grid-col-2 text-center">Transcriptions</div>
                <div className="grid-col-2 text-center">Tags</div>
                <div className="grid-col-2 text-center">Comments</div>
                <div className="grid-col-2 text-center">Extracted Text</div>
              </div>
            )}
          </>
        )}
      </div>
      {active ? (
        <>
          {activeContentsView == 'grid' && gridViewJSX}
          {activeContentsView == 'list' && listViewJSX}
        </>
      ) : (
        defaultViewJSX
      )}
    </div>
  )
}

ContentsPanel.defaultProps = {
  loaded: true,
  screenSize: 'desktop',
}

ContentsPanel.propTypes = {
  // active: PropTypes.bool,
  extractedData: PropTypes.array,
  loaded: PropTypes.bool,
  screenSize: PropTypes.oneOf(['desktop', 'tablet-lg', 'tablet', 'mobile']),
  // handleSetContentsPanel: PropTypes.func,
}
