import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
//Context
import SearchContext from '../../contexts/SearchContext'

const useSearchInput = (onSubmit, isSearchWithin) => {
  const { pathname } = useLocation()
  const {
    activeQueryParams,
    handleActiveQueryParams,
    handleSearchChange,
    searchQuery,
    setSearchQuery,
    submittedQuery,
  } = useContext(SearchContext) || {}

  const { search } = useLocation()
  const query = queryString.parse(search)

  const [value, setValue] = useState(query.q || activeQueryParams.q || '')
  const [searchWithinValue, setSearchWithinValue] = useState('')

  const handleOnChange = (event) => {
    isSearchWithin
      ? setSearchWithinValue(event.target.value)
      : setValue(event.target.value)
    handleSearchChange(event)
    if (pathname.indexOf('/search') > -1 || pathname == '/') {
      let newParams = Object.assign({}, activeQueryParams)
      newParams.q = event.target.value
      handleActiveQueryParams(newParams)
    }
  }

  const handleOnSubmit = (event) => {
    const passValue = isSearchWithin
      ? searchWithinValue
      : value
      ? value
      : searchQuery
      ? searchQuery
      : submittedQuery
      ? submittedQuery
      : ''

    onSubmit(event, passValue)
  }

  useEffect(() => {
    if (!pathname.includes('/search-within')) {
      if (query.q) setValue(query.q)
      else setValue(activeQueryParams.q || '')
    }
  }, [search])

  return {
    handleOnChange,
    handleOnSubmit,
    searchQuery,
    setSearchQuery,
    value,
    searchWithinValue,
  }
}
export default useSearchInput
