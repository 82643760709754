const exactPhrase = (string) => {
  if (
    (string?.[0] == "'" && string[string.length - 1] == "'") ||
    (string?.[0] == '"' && string[string.length - 1] == '"')
  ) {
    return true
  } else return false
}

export function countWords(str) {
  return str.trim()?.split(/\s+/).length
}

export function truncateAfter(string, search) {
  if (!string || !search) return
  if (exactPhrase(search))
    return string?.slice(string?.indexOf(search) + search.length)
  else
    return string?.slice(
      string?.toLowerCase()?.indexOf(search?.toLowerCase()) + search.length
    )
}

export function truncateBefore(string, search) {
  if (!string || !search) return
  if (exactPhrase(search)) return string?.slice(0, string?.indexOf(search))
  else
    return string?.slice(
      0,
      string?.toLowerCase()?.indexOf(search?.toLowerCase())
    )
}

export function truncate(string, amount, reverse = false) {
  if (!string) return
  if (string?.split(' ').length <= amount) return string
  if (reverse)
    return (
      '...' + string?.split(' ').reverse().splice(0, amount).reverse().join(' ')
    )
  return string?.split(' ').splice(0, amount).join(' ') + '...'
}
